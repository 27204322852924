import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarReservations,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';

const Reservations = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,hotelgroup,touroperator,touroperatorgroup,roomtype} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "name": '',
      "resgroup": '',
      "bookingnumber": '',
      "roomtype": null,
      "foliodesde": '',
      "foliohasta": '',
      "checkindatedesde": null,
      "checkindatehasta":null,
    });

    useEffect(() => {
      dispatch(IniciarReservations());
    }, [dispatch,formApp ]);

    const ejecucion = () => {
         let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
         }else{variables += "null"}

         if(form.hotelgroup){variables += `|${form.hotelgroup}`
         }else{variables += "|null"}

         if(form.touroperator){variables += `|${form.touroperator}`
         }else{variables += "|null"}

         if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
         }else{variables += "|null"}

         if(form.name){variables += `|${form.name}`
         }else{variables += "|null"}

         if(form.resgroup){variables += `|${form.resgroup},`
         }else{variables += "|null"}

         if(form.bookingnumber){variables += `|${form.bookingnumber},`
         }else{variables += "|null"}

         if(form.roomtype){variables += `|${form.roomtype},`
         }else{variables += "|null"}
         
         if(form.foliodesde){variables += `|${form.foliodesde},`
         }else{variables += "|null,"}

         if(form.foliohasta){variables += `${form.foliohasta}`
         }else{variables += "null"}

         if(form.checkindatedesde){variables += `|${form.checkindatedesde},`
         }else{variables += "|null,"}
        
         if(form.checkindatehasta){variables += `${form.checkindatehasta}`
         }else{variables += "null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
      
        dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

                 <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

                <div className={classes.input} >
                    <TextField 
                      className={classes.textlabel}
                      size='small'
                      label="Name" 
                      variant="outlined"
                      value={form.name}
                      onChange={(value) => setForm(_.set({ ...form }, 'name', value.target.value)) } />
                </div>
                <div className={classes.input}>
                    <TextField 
                      className={classes.textlabel}
                      size='small'
                      label="Res Group" 
                      variant="outlined"
                      value={form.resgroup}
                      onChange={(value) => setForm(_.set({ ...form }, 'resgroup', value.target.value)) } />
              </div>
              
                <div className={classes.input}>
                    <TextField 
                        className={classes.textlabel}
                        size='small'
                        label="Booking Number" 
                        variant="outlined"
                        value={form.bookingnumber}
                        onChange={(value) => setForm(_.set({ ...form }, 'bookingnumber', value.target.value)) } />
               </div>
                  
                <Autocompetesinapp 
                     multiple 
                    disableCloseOnSelect 
                    options={roomtype} 
                    name="Room Type"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'roomtype', ValorSelect(newValue))) 
                    }}
                  />

              <div className={classes.input} >
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Folio From" 
                     variant="outlined"
                     value={form.foliodesde}
                     onChange={(value) => setForm(_.set({ ...form }, 'foliodesde', value.target.value)) } 
                     style={{paddingRight:10}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />

                  <TextField 
                     className={classes.textlabel}
                     size='small'
                     label="Folio To" 
                     variant="outlined"
                     value={form.foliohasta}
                     onChange={(value) => setForm(_.set({ ...form }, 'foliohasta', value.target.value)) } 
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />
                </div>
              
                    <div className={classes.input} >
                        <Pickersinapp
                          label="Check in From"
                          value={form.checkindatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatedesde', null))}}
                          style={{paddingRight: 10}}
                          
                       />
                       <Pickersinapp
                          label="Check in To"
                          value={form.checkindatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatehasta', null))}}
                        />
                  </div>

              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default Reservations;

Reservations.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
