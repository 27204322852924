export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const RESET_PASSWORD = 'reset_password';
export const GET_MENU = 'get_menu';

export const SET_MENUSELECT = 'SET_MENUSELECT';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';

//Solicitudes
export const GET_INFORMES = 'GET_INFORMES';
export const GET_PLANTILLAS = 'GET_PLANTILLAS';

export const SET_HOTEL = 'SET_HOTEL';
export const SET_HOTELGROUP = 'SET_HOTELGROUP';
export const SET_TOUROPERATOR = 'SET_TOUROPERATOR';
export const SET_TOUROPERATORGROUP = 'SET_TOUROPERATORGROUP';
export const SET_MARKET = 'SET_MARKET';
export const SET_PAYMENTCONDITION = 'SET_PAYMENTCONDITION';
export const SET_ORDENARPOR = 'SET_ORDENARPOR';
export const SET_VERSIONCCL = 'SET_VERSIONCCL';
export const SET_ROOMTYPE = 'SET_ROOMTYPE';
export const SET_INVTYPE = 'SET_INVTYPE';
export const SET_ESTATUSFACTURAS = 'SET_ESTATUSFACTURAS';
export const SET_INCLUIRFACTURAS = 'SET_INCLUIRFACTURAS';
export const SET_TIPODOCUMENTO = 'SET_TIPODOCUMENTO';
export const SET_TIPODOCUMENTOFACTURA = 'SET_TIPODOCUMENTOFACTURA';
export const SET_ACCOUNTTYPE = 'SET_ACCOUNTTYPE';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_CARTERA = 'SET_CARTERA';
export const SET_FRECUENCY = 'SET_FRECUENCY';
export const SET_INVOICECONDITION = 'SET_INVOICECONDITION';
export const SET_TIPOREPORTE = 'SET_TIPOREPORTE';

export const SET_DOCCLASS = 'SET_DOCCLASS';
export const SET_TRANSACTIONCODE = 'SET_TRANSACTIONCODE';
export const SET_DOCUMENTSING = 'SET_DOCUMENTSING';
export const SET_DEPARTAMENT = 'SET_DEPARTAMENT';
export const SET_RESPONSIBLE = 'SET_RESPONSIBLE';

export const SET_MESES = 'SET_MESES';
export const SET_CLASS = 'SET_CLASS';
//mantenimientos

export const SET_PLANTILLAS = 'SET_PLANTILLAS';
export const SET_INFORMES = 'SET_INFORMES';
export const SET_HOTELES = 'SET_HOTELES';
export const SET_TOURSOPERATOR = 'SET_TOURSOPERATOR';
export const SET_LISTITEMSPRESUPUESTOCOBROS = 'SET_LISTITEMSPRESUPUESTOCOBROS';
export const SET_ITEMPRESUPUESTOCOBROBYID = 'SET_ITEMPRESUPUESTOCOBROBYID';
export const SET_PRESUPUESTOCOBROEXCLUIR = 'SET_PRESUPUESTOCOBROEXCLUIR';
export const SET_EJECIONPROYECTOHOTELES = 'SET_EJECIONPROYECTOHOTELES';
export const SET_TTOODIASHABILES = 'SET_TTOODIASHABILES';
export const SET_TIPOSDEFACTURACION = 'SET_TIPOSDEFACTURACION';
export const SET_HOTELPAIS = 'SET_HOTELPAIS';
export const SET_PAISES = 'SET_PAISES';
export const SET_LISTADOANOSPAIS = 'SET_LISTADOANOSPAIS';
export const SET_LISTADODIASFESTIVOS = 'SET_LISTADODIASFESTIVOS';
export const SET_LISTITEMPRESUPUESTOS = 'SET_LISTITEMPRESUPUESTOS';
export const SET_PAISFESTIVOSELECT = 'SET_PAISFESTIVOSELECT';
export const SET_SOLICITUDES = 'SET_SOLICITUDES';
export const SET_NOTIFICACIONES = 'SET_NOTIFICACIONES';
export const SET_USUARIOS = 'SET_USUARIOS';
export const SET_ADDUSUARIO = 'SET_ADDUSUARIO';
export const SET_EDITUSUARIO = 'SET_EDITUSUARIO';
export const SET_CLOSEDILOGUSUARIO = 'SET_CLOSEDILOGUSUARIO';
export const SET_ROLESBYUSUARIO = 'SET_ROLESBYUSUARIO';
export const SET_MENU = 'SET_MENU';
export const SET_ROLES = 'SET_ROLES';
export const SET_ADDROLES = 'SET_ADDROLES';
export const SET_EDITROLES = 'SET_EDITROLES';
export const SET_CLOSEDILOGROLES = 'SET_CLOSEDILOGROLES';
export const SET_FORMROL = 'SET_FORMROL';
export const SET_MENUROL = 'SET_MENUROL';
export const SET_OPENDIALOG = 'SET_OPENDIALOG';
export const SET_CARTERAS = 'SET_CARTERAS';
export const SET_DIALOGCARTERAS = 'SET_DIALOGCARTERAS';

export const SET_PAYMODE = 'SET_PAYMODE';
export const SET_CREDITDAYS = 'SET_CREDITDAYS';
export const SET_CONTACTTYPE = 'SET_CONTACTTYPE';
export const SET_STATE = 'SET_STATE';

export const SET_RATETYPE = 'SET_RATETYPE';
export const SET_RATERECEIVED = 'SET_RATERECEIVED';

export const SET_CONCEPTOSDESCUENTOS = 'SET_CONCEPTOSDESCUENTOS';
export const SET_DIALOGCONCEPTOSDESCUENTOS = 'SET_DIALOGCONCEPTOSDESCUENTOS';
export const SET_OPENPRESUPUESTOCOBRODIALOG = 'SET_OPENPRESUPUESTOCOBRODIALOG';
export const SET_CLOSEPRESUPUESTOCOBRODIALOG = 'SET_CLOSEPRESUPUESTOCOBRODIALOG';

//Registrar Cobros

export const SET_COBROSNOREGULARES = 'SET_COBROSNOREGULARES';
export const SET_OPENCOBROSNOREGULARESDIALOG = 'SET_OPENCOBROSNOREGULARESDIALOG';
export const SET_CLOSECOBROSNOREGULARESDIALOG = 'SET_CLOSECOBROSNOREGULARESDIALOG';

// Agrupacion Cartera

export const SET_CARTERAGRUPO = 'SET_CARTERAGRUPO';
export const SET_OPENCARTERAGRUPODIALOG = 'SET_OPENCARTERAGRUPODIALOG';
export const SET_CLOSECARTERAGRUPODIALOG = 'SET_CLOSECARTERAGRUPODIALOG';
export const SET_CARTERAGRUPODETALLE = 'SET_CARTERAGRUPODETALLE';
export const SET_CARTERAGRUPOSELECT = 'SET_CARTERAGRUPOSELECT';

export const SET_AGRUPACIONCARTERA = 'SET_AGRUPACIONCARTERA';

//
export const SET_OPENPERIODOANODIALOG = 'SET_OPENPERIODOANODIALOG';
export const SET_CLOSEPERIODOANODIALOG = 'SET_CLOSEPERIODOANODIALOG';
export const SET_PERIODOANOSELECT = 'SET_PERIODOANOSELECT';
export const SET_PERIODOANOS = 'SET_PERIODOANOS';
export const SET_ANOS = 'SET_ANOS';

//
export const SET_OPENPERIODOSSEMANALDIALOG = 'SET_OPENPERIODOSSEMANALDIALOG';
export const SET_CLOSEPERIODOSSEMANALDIALOG = 'SET_CLOSEPERIODOSSEMANALDIALOG';
export const SET_PERIODOSSEMANAL = 'SET_PERIODOSSEMANAL';

//
export const SET_CAMPOSHEADER = 'SET_CAMPOSHEADER';
export const SET_CAMPOSFORMULARIO = 'SET_CAMPOSFORMULARIO';

//
export const SET_PORTAFOLIOS = 'SET_PORTAFOLIOS';
export const SET_PORTAFOLIOSELECT = 'SET_PORTAFOLIOSELECT';
export const SET_PORTAFOLIOSDETALLE = 'SET_PORTAFOLIOSDETALLE';
