import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Login from './Auth/Login';
import Error404 from './404';
import Grupos from './ListadoEjecucion/index';
import Informes from './Mantenimientos/ActualizacionInformes';
import Plantillas from './Mantenimientos/ActualizacionPlantillas';
import PresupuestoCobroExcluirTTO from './Mantenimientos/PresupuestoCobroExcluirTTO';
import AsignarPaisHotel from './Mantenimientos/AsignarPaisHotel';
import DiashabilesTTO from './Mantenimientos/DiashabilesTTO';
import DiasFestivosPaises from './Mantenimientos/DiasFestivosPaises';
import ItemPresupuestoCobros from './Mantenimientos/ItemPresupuestoCobros';
import CambiarClave from './Herramientas/CambiarClaves';
import ConsultarSolicitadas from './Herramientas/ConsultasSolicitudes';
import Usuarios from './Herramientas/Usuarios';
import ActualizacionMenu from './Mantenimientos/ActualizacionMenus';
import Roles from './Herramientas/Roles';
import Carteras from './Mantenimientos/Carteras';
import ConceptosPagos from './Mantenimientos/ConceptoDescuento';
import AgrupacionCartera from './Mantenimientos/AgrupacionCartera';
import RegistrarCobrosnoRegulares from './Mantenimientos/RegistrarCobrosnoRegulares';
import PeriodoPresupuestoMensual from './Mantenimientos/PeriodoPresupuestoMensual';
import PeriodoPresupuestoSemanal from './Mantenimientos/PeriodoPresupuestoSemanal';
import PortafoliosyCarteras from './Mantenimientos/PortafoliosyCartera';
import EjecucionProyeccionHoteles from './Mantenimientos/EjecucionProyeccionHoteles';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { menu } = useSelector(({ common }) => common);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to="/apps/herramienta/consolicitud" />;
  } else if (authUser && location.pathname === '/signin') {
    if (menu.length === 0) {
      return <Redirect to="/apps/herramienta/consolicitud" />;
    } else {
      if (menu[0].children.length > 0) {
        return <Redirect to={menu[0].children[0].link} />;
      } else {
        return <Redirect to="/apps/herramienta/consolicitud" />;
      }
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <RestrictedRoute path="/apps/forms/grupos" component={Grupos} />
        <RestrictedRoute path="/apps/forms/contratos" component={Grupos} />
        <RestrictedRoute path="/apps/forms/reservaciones" component={Grupos} />
        <RestrictedRoute path="/apps/forms/tarifas" component={Grupos} />
        <RestrictedRoute path="/apps/forms/facturacion" component={Grupos} />
        <RestrictedRoute path="/apps/forms/cobros" component={Grupos} />
        <RestrictedRoute path="/apps/forms/pagocorr" component={Grupos} />
        <RestrictedRoute path="/apps/forms/contab" component={Grupos} />
        <RestrictedRoute path="/apps/forms/geropera" component={Grupos} />
        <RestrictedRoute path="/apps/forms/actplantilla" component={Plantillas} />
        <RestrictedRoute path="/apps/forms/actinforme" component={Informes} />
        <RestrictedRoute path="/apps/mantenimiento/prescobrottooexcl" component={PresupuestoCobroExcluirTTO} />
        <RestrictedRoute path="/apps/mantenimiento/asigpaishotel" component={AsignarPaisHotel} />
        <RestrictedRoute path="/apps/mantenimiento/diashabilttoo" component={DiashabilesTTO} />
        <RestrictedRoute path="/apps/mantenimiento/diasfestivoxpais" component={DiasFestivosPaises} />
        <RestrictedRoute path="/apps/mantenimiento/parprecobros" component={ItemPresupuestoCobros} />
        <RestrictedRoute path="/apps/herramienta/changepwd" component={CambiarClave} />
        <RestrictedRoute path="/apps/herramienta/consolicitud" component={ConsultarSolicitadas} />
        <RestrictedRoute path="/apps/forms/usuario" component={Usuarios} />
        <RestrictedRoute path="/apps/forms/actmenu" component={ActualizacionMenu} />
        <RestrictedRoute path="/apps/forms/rol" component={Roles} />
        <RestrictedRoute path="/apps/mantenimiento/carteras" component={Carteras} />
        <RestrictedRoute path="/apps/mantenimiento/desctosconcepto" component={ConceptosPagos} />
        <RestrictedRoute path="/apps/mantenimiento/carteragrupos" component={AgrupacionCartera} />
        <RestrictedRoute path="/apps/mantenimiento/cobronoregular" component={RegistrarCobrosnoRegulares} />
        <RestrictedRoute path="/apps/mantenimiento/periodopresmensual" component={PeriodoPresupuestoMensual} />
        <RestrictedRoute path="/apps/mantenimiento/periodopressemanal" component={PeriodoPresupuestoSemanal} />
        <RestrictedRoute path="/apps/mantenimiento/portafolioprescobro" component={PortafoliosyCarteras} />
        <RestrictedRoute path="/apps/mantenimiento/hotelnooperativo" component={EjecucionProyeccionHoteles} />
        <RestrictedRoute component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
