import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarFacturasExpedida,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';

const FacturasExpedida = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "checkindatedesde": null,
      "checkindatehasta": null,
      "checkoutdatedesde": null,
      "checkoutdatehasta": null,
      "invoicedatedesde": null,
      "invoicedatehasta": null,
      "invnumberdesde":'',
      "invnumberhasta":'',
      "reviewdatedesde": null,
      "reviewdatehasta": null,
    });

     useEffect(() => {
        dispatch(IniciarFacturasExpedida(formApp.formAppID));
     }, [dispatch,formApp ]);



    const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.checkindatedesde){variables += `|${form.checkindatedesde},`
         }else{variables += "|null,"}

        if(form.checkindatehasta){variables += `${form.checkindatehasta}`
        }else{variables += "null"}

        if(form.checkoutdatedesde){variables += `|${form.checkoutdatedesde},`
        }else{variables += "|null,"}

        if(form.checkoutdatehasta){variables += `${form.checkoutdatehasta}`
        }else{variables += "null"}

        if(form.invoicedatedesde){variables += `|${form.invoicedatedesde},`
        }else{variables += "|null,"}

        if(form.invoicedatehasta){variables += `${form.invoicedatehasta}`
        }else{variables += "null"}
        
        if(form.invnumberdesde){variables += `|${form.invnumberdesde},`
        }else{variables += "|null,"}

        if(form.invnumberhasta){variables += `${form.invnumberhasta}`
        }else{variables += "null"}
  
        if(form.reviewdatedesde){variables += `|${form.reviewdatedesde},`
        }else{variables += "|null,"}

        if(form.reviewdatehasta){variables += `${form.reviewdatehasta}`
        }else{variables += "null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

        dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                 
                <div className={classes.input} >
                        <Pickersinapp
                          label="Check In Date From"
                          value={form.checkindatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check In Date To"
                          value={form.checkindatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkindatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkindatehasta', null))}}
                        />
                  </div>

                  <div className={classes.input} >
                        <Pickersinapp
                          label="Check Out Date From"
                          value={form.checkoutdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkoutdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkoutdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check Out Date To"
                          value={form.checkoutdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkoutdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkoutdatehasta', null))}}
                        />
                  </div>

                  <div className={classes.input} >
                        <Pickersinapp
                          label="Invoice Date From"
                          value={form.invoicedatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Invoice Date To"
                          value={form.invoicedatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatehasta', null))}}
                        />
                  </div>

             
              <div className={classes.input} >
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Invoice Number From" 
                     variant="outlined"
                     value={form.invnumberdesde}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberdesde', value.target.value)) } 
                     style={{paddingRight:10}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />

                  <TextField 
                     className={classes.textlabel}
                     size='small'
                     label="Invoice Number To" 
                     variant="outlined"
                     value={form.invnumberhasta}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberhasta', value.target.value)) } 
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}/>
                </div>
                    
                <div className={classes.input} >
                        <Pickersinapp
                          label="Review Date From"
                          value={form.reviewdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Review Date To"
                          value={form.reviewdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatehasta', null))}}
                        />
                  </div>
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
            </DialogContent>  
        </Dialog>
    )
};

export default FacturasExpedida;

FacturasExpedida.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
