import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarReviewdInvoice,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';

const ReviewedInvoices = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,ordenarpor,versionccl} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "market": null,
      "bookingdatedesde": null,
      "bookingdatehasta": null,
      "invoicedatedesde": null,
      "invoicedatehasta": null,
      "reviewdatedesde": null,
      "reviewdatehasta": null,
      "checkinfromdatedesde": null,
      "checkinfromdatehasta": null,
      "bookingnumber":'',
      "paymentcondition": null,
      "ordenarpor": null,
      "version": null,
    });

    useEffect(() => {
      dispatch(IniciarReviewdInvoice(formApp.formAppID));
    }, [dispatch,formApp ]);



    const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.bookingdatedesde){variables += `|${form.bookingdatedesde},`
        }else{variables += "|null,"}

        if(form.bookingdatehasta){variables += `${form.bookingdatehasta}`
        }else{variables += "null"}

        if(form.invoicedatedesde){variables += `|${form.invoicedatedesde},`
        }else{variables += "|null,"}

        if(form.invoicedatehasta){variables += `${form.invoicedatehasta}`
        }else{variables += "null"}
        
        if(form.reviewdatedesde){variables += `|${form.reviewdatedesde},`
        }else{variables += "|null,"}

        if(form.reviewdatehasta){variables += `${form.reviewdatehasta}`
        }else{variables += "null"}

        if(form.checkinfromdatedesde){variables += `|${form.checkinfromdatedesde},`
        }else{variables += "|null,"}

        if(form.checkinfromdatehasta){variables += `${form.checkinfromdatehasta}`
        }else{variables += "null"}

        if(form.bookingnumber){variables += `|${form.bookingnumber}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":form.version, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

        dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
          
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                        setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={market} 
                    name="Market"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                    }}
                />
 
              <Autocompetesinapp 
                  options={paymentcondition} 
                  name="Payment Condition"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                  }}
                />
                  
                  <div className={classes.input} >
                        <Pickersinapp
                          label="Booking Date From"
                          value={form.bookingdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                            
                       <Pickersinapp
                          label="Booking Date To"
                          value={form.bookingdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'bookingdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'bookingdatehasta', null))}}
                        />
                   </div>

                   <div className={classes.input} >
                        <Pickersinapp
                            label="Invoice Date From"
                            value={form.invoicedatedesde}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatedesde', null))}}
                            style={{paddingRight: 10}}
                        />
                        
                        <Pickersinapp
                            label="Invoice Date To"
                            value={form.invoicedatehasta}
                            onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invoicedatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invoicedatehasta', null))}}
                         //   style={{paddingRight: 10}}
                        />
                    </div>

                    <div className={classes.input} >
                        <Pickersinapp
                                label="Review Date From"
                                value={form.reviewdatedesde}
                                onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatedesde', null))}}
                                style={{paddingRight: 10}}
                            />
                            
                            <Pickersinapp
                                label="Review Date To"
                                value={form.reviewdatehasta}
                                onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'reviewdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'reviewdatehasta', null))}}
                            />
                    </div>

                    <div className={classes.input} >
                        <Pickersinapp
                          label="Check In Date From"
                          value={form.checkinfromdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinfromdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinfromdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check In  Date To"
                          value={form.checkinfromdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinfromdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinfromdatehasta', null))}}
                        />
                  </div>

                    <div className={classes.input} >
                        <TextField 
                          style={{paddingRight: 10}}
                          className={classes.textlabel}
                          size='small'
                          label="Booking Number" 
                          variant="outlined"
                          value={form.bookingnumber}
                          onChange={(value) => setForm(_.set({ ...form }, 'bookingnumber', value.target.value)) } />
                    </div>


              <Typography variant='h6'>Ordenar Por</Typography>
              <Divider classes={{root: classes.divider}}/>

                <Autocompetesinapp 
                  options={ordenarpor} 
                  name="Ordenar Por"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                  }}
                />

              <Typography variant='h6'>Versión</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Autocompetesinapp 
                  options={versionccl} 
                  name="Versión"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'version', ValorSelect(newValue))) 
                  }}
                />
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default ReviewedInvoices;

ReviewedInvoices.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
