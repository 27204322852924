import _ from 'lodash';
import React,{useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
//import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarTourOperator,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const TourOperatorReport = ({ open, onCloseDialog,formApp }) => {
     const classes = useStyles();
     const dispatch = useDispatch();
     const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { touroperatorgroup,market,paymentcondition,paymode,creditdays,contacttype,statefrm} = useSelector(({ ejecucion }) => ejecucion);
     const { form, setForm } = useForm({
      "name": null,
      "city": null,
      "country": null,
      "touroperatorgroup": null,
      "market": null,
      "paymentcondition": null,
      "paymode": null,
      "creditdays": null,
      "contacttype": null,
      "state": null,
      "creationdatedesde": null,
      "creationdatehasta": null,
      "withdetails": null,
    });

     useEffect(() => {
       dispatch(IniciarTourOperator(formApp.formAppID));
    }, [dispatch,formApp ]);

     const ejecucion = () => {
         let variables = "";
        
         if(form.name){variables += `${form.name}`
         }else{variables += "null"}

         if(form.city){variables += `|${form.city}`
         }else{variables += "|null"}

         if(form.country){variables += `|${form.country}`
         }else{variables += "|null"}

         if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
         }else{variables += "|null"}

         if(form.market){variables += `|${form.market}`
         }else{variables += "|null"}

         if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

         if(form.paymode){variables += `|${form.paymode}`
          }else{variables += "|null"}

        if(form.creditdays){variables += `|${form.creditdays}`
         }else{variables += "|null"}

        if(form.contacttype){variables += `|${form.contacttype}`
        }else{variables += "|null"}

        if(form.state){variables += `|${form.state}`
        }else{variables += "|null"}

         if(form.creationdatedesde){variables += `|${form.creationdatedesde},`
         }else{variables += "|null,"}

         if(form.creationdatehasta){variables += `${form.creationdatehasta}`
         }else{variables += "null"}

         if(form.withdetails){variables += `|${form.withdetails}`
         }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":form.version, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

         dispatch(SolicitarEjecucion(Solicitar))
    
       };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography color='inherit'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Box display="flex" justifyContent="flex-start" flexDirection="column" my={4}>
                
                <TextField 
                  className={classes.textlabel}
                  size='small'
                  label="Name" 
                  variant="outlined"
                  value={form.name}
                  onChange={(value) => setForm(_.set({ ...form }, 'name', value.target.value)) } />
              
                <TextField 
                  className={classes.textlabel}
                  size='small'
                  label="City" 
                  variant="outlined"
                  value={form.city}
                  onChange={(value) => setForm(_.set({ ...form }, 'city', value.target.value)) } 
                  style={{marginTop:10,marginBottom:10}}
                  />
             
                <TextField 
                  className={classes.textlabel}
                  size='small'
                  label="Country" 
                  variant="outlined"
                  value={form.country}
                  onChange={(value) => setForm(_.set({ ...form }, 'country', value.target.value)) } />
                  
             </Box>
             
              <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={market} 
                    name="Market"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                  options={paymentcondition} 
                  name="Payment Condition"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={paymode} 
                    name="Pay Mode"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'paymode', ValorSelect(newValue))) 
                    }}
                />

                  <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={creditdays} 
                    name="Credit Days"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'creditdays', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={contacttype} 
                    name="Contact Type"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'contacttype', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={statefrm} 
                    name="State"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'state', ValorSelect(newValue))) 
                    }}
                />

                    <Box my={-5}>
                        <Pickersinapp
                          label="Creation Date From"
                          value={form.creationdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'creationdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'creationdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                            
                       <Pickersinapp
                          label="Creation Date To"
                          value={form.creationdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'creationdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'creationdatehasta', null))}}
                          style={{paddingRight: 10}}
                        />
                   </Box>

                  <FormControlLabel
                    control={ <Checkbox
                      checked={form.withnotes}
                      onChange={x => {setForm(_.set({ ...form }, 'withdetails', x.target.checked))}}
                      color="primary"
                    />}
                    label="With Detail"
                    style={{marginTop:25}}
                  />

              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default TourOperatorReport;

TourOperatorReport.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
