import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarInvoiceJournal,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import Grid from '@material-ui/core/Grid';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';



const InvoiceJournal = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,invtype,roomtype,estatusfacturas,incluirfacturas,tipodocumentofactura,ordenarpor} = useSelector(({ ejecucion }) => ejecucion);
     const { form, setForm } = useForm({
        "hotel": null,
        "hotelgroup": null,
        "touroperator": null,
        "touroperatorgroup": null,
        "paymentcondition": null,
        "market": null,
        "invtype": null,
        "roomtype": null,
        "checkinfromdatedesde": null,
        "checkinfromdatehasta": null,
        "invnumberdesde": null,
        "invnumberhasta": null,
        "invdatedesde":null,
        "invdatehasta": null,
        "estatusfactura": null,
        "incluirfacturascanceladas": null,
        "tipodocumento": null,
        "ordenarpor": null,
     });

    useEffect(() => {
      dispatch(IniciarInvoiceJournal(formApp.formAppID));
    }, [dispatch,formApp ]);

    const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.invtype){variables += `|${form.invtype}`
        }else{variables += "|null"}

        if(form.roomtype){variables += `|${form.roomtype}`
        }else{variables += "|null"}

        if(form.checkinfromdatedesde){variables += `|${form.checkinfromdatedesde},`
        }else{variables += "|null,"}

        if(form.checkinfromdatehasta){variables += `${form.checkinfromdatehasta}`
        }else{variables += "null"}

        if(form.invnumberdesde){variables += `|${form.invnumberdesde},`
        }else{variables += "|null,"}

        if(form.invnumberhasta){variables += `${form.invnumberhasta}`
        }else{variables += "null"}
        
        if(form.invdatedesde){variables += `|${form.invdatedesde},`
        }else{variables += "|null,"}

        if(form.invdatehasta){variables += `${form.invdatehasta}`
        }else{variables += "null"}

        if(form.estatusfactura){variables += `|${form.estatusfactura}`
        }else{variables += "|null"}

        if(form.incluirfacturascanceladas){variables += `|${form.incluirfacturascanceladas}`
        }else{variables += "|null"}

        if(form.tipodocumento){variables += `|${form.tipodocumento}`
        }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
      
        dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                    options={paymentcondition} 
                    name="Payment Condition"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={market} 
                  name="Market"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                  }}
                />
               
               
               <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={invtype} 
                  name="Invoice Type"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'invtype', ValorSelect(newValue))) 
                  }}
                />

                
               <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={roomtype} 
                  name="Room Type"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'roomtype', ValorSelect(newValue))) 
                  }}
                />

                <div className={classes.input} >
                        <Pickersinapp
                          label="Check In Date From"
                          value={form.checkinfromdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinfromdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinfromdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check In  Date To"
                          value={form.checkinfromdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinfromdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinfromdatehasta', null))}}
                        />
                  </div>
             

              <div className={classes.input} >
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Invoice Number From" 
                     variant="outlined"
                     value={form.invnumberdesde}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberdesde', value.target.value)) } 
                     style={{paddingRight:10}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />

                  <TextField 
                     className={classes.textlabel}
                     size='small'
                     label="Invoice Number To" 
                     variant="outlined"
                     value={form.invnumberhasta}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberhasta', value.target.value)) } 
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}/>
                </div>

                <div className={classes.input} >
                        <Pickersinapp
                          label="Invoice Date From"
                          value={form.invdatedesde}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invdatedesde', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invdatedesde', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Invoice Date To"
                          value={form.invdatehasta}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invdatehasta', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invdatehasta', null))}}
                        />
                  </div>
                
                  <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={estatusfacturas} 
                  name="Estatus Facturas"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'estatusfactura', ValorSelect(newValue))) 
                  }}
                />

        <Grid container spacing={3} style={{marginTop:5}}>
              <Grid item xs={6}>
                  <Autocompetesinapp 
                    options={incluirfacturas} 
                    name="¿Incluir Facturas Canceladas?"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'incluirfacturascanceladas', ValorSelect(newValue))) 
                    }}
                  />
                </Grid>
              <Grid item xs={6}>
                  <Autocompetesinapp 
                    options={tipodocumentofactura} 
                    name="Tipo Documento"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'tipodocumento', ValorSelect(newValue))) 
                    }}
                  />
                </Grid>
         </Grid>

             <Autocompetesinapp 
                  options={ordenarpor} 
                  name="Tipo de Salida"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                  }}
                />

                  </div>
                  <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
             </DialogContent>  
        </Dialog>
    )
};

export default InvoiceJournal;

InvoiceJournal.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
