import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarDocumentBalance,getResponsible,getclass,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';
import TextField from '@material-ui/core/TextField';

const DocumentBalance = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,
            hotelgroup,
            touroperator,
            touroperatorgroup,
            market,
            paymentcondition,
            docclass,
            classdoc,
            transactioncode,
            documentsing,
            departament,
            responsible,
            ordenarpor,
            versionccl,
            accounttype,
            
          } = useSelector(({ ejecucion }) => ejecucion);
    
  const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "market": null,
      "paymentcondition": null,
      "datedesde":null,
      "datehasta": null,
      "asofdate":null,
      "docclass": null,
      "class":null,
      "transactioncode":null,
      "accounttype":null,
      "documentsign":null,
      "departament":null,
      "responsible":null,
      "exlcuircedarcreek":null,
      "ordenarpor":null,
      "version":null,
      "withnotes":null,
      "approveddocuments":null

    });

     useEffect(() => {
       dispatch(IniciarDocumentBalance(formApp.formAppID));
     }, [dispatch,formApp.formAppID ]);


     const ejecucion = () => {
        let variables = "";
        
         if(form.hotel){variables += `${form.hotel}`
         }else{variables += "null"}

         if(form.hotelgroup){variables += `|${form.hotelgroup}`
         }else{variables += "|null"}

         if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}
              
        if(form.datedesde){variables += `|${form.datedesde},`
        }else{variables += "|null,"}

        if(form.datehasta){variables += `${form.datehasta}`
        }else{variables += "null"}

        if(form.asofdate){variables += `|${form.asofdate}`
        }else{variables += "|null"}

        if(form.docclass){variables += `|${form.docclass}`
        }else{variables += "|null"}

        if(form.class){variables += `|${form.class}`
        }else{variables += "|null"}

        if(form.transactioncode){variables += `|${form.transactioncode}`
        }else{variables += "|null"}

        if(form.accounttype){variables += `|${form.accounttype}`
        }else{variables += "|null"}

        if(form.documentsign){variables += `|${form.documentsign}`
        }else{variables += "|null"}

        if(form.departament){variables += `|${form.departament}`
        }else{variables += "|null"}

       if(form.responsible){variables += `|${form.responsible}`
       }else{variables += "|null"}

       if(form.exlcuircedarcreek){variables += `|${form.exlcuircedarcreek}`
      }else{variables += "|null"}
   
        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":form.version, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":null
        }

        dispatch(SolicitarEjecucion(Solicitar))
       };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  
          
            <Typography variant='h4'>Criterios de Búsqueda</Typography>
            <Divider classes={{root: classes.divider}}/>

            <Autocompetesinapp 
              multiple 
              disableCloseOnSelect 
              options={hotel} 
              name="Hotel"
              onChange={(event, newValue) => {
                setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
              }}
            />

            <Autocompetesinapp 
              options={hotelgroup} 
              name="Hotel Group"
              onChange={(event, newValue) => {
                setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
              }}
            />

                
            <Autocompetesinapp 
              multiple 
              disableCloseOnSelect 
              options={touroperator} 
              name="Tour Operator"
              onChange={(event, newValue) => {
                setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
              }}
            />

            <Autocompetesinapp 
              options={touroperatorgroup} 
              name="Tour Operator Group"
              onChange={(event, newValue) => {
                 setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
              }}
            />

            <Autocompetesinapp 
              multiple 
              disableCloseOnSelect 
              options={market} 
              name="Market"
              onChange={(event, newValue) => {
                setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
              }}
            />

            <Autocompetesinapp 
              options={paymentcondition} 
              name="Payment Condition"
              onChange={(event, newValue) => {
                setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                }}
            />

             
                <div className={classes.input} >
                <div>
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Document From" 
                     variant="outlined"
                     value={form.datedesde}
                     onChange={(value) => setForm(_.set({ ...form }, 'datedesde', value.target.value)) } 
                     style={{width:350,marginRight:10}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                    />
                
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Document to" 
                     variant="outlined"
                     value={form.datehasta}
                     onChange={(value) => setForm(_.set({ ...form }, 'datehasta', value.target.value)) } 
                     style={{width:350}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />
                </div>
                </div>

            
                  <Pickersinapp
                     label="As Of Date"
                     value={form.asofdate}
                     onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'asofdate', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'asofdate', null))}}
                   />
            

                  <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={docclass} 
                    name="Doc Class"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'docclass', ValorSelect(newValue))) 
                       dispatch(getclass(newValue.value))
                    }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={classdoc} 
                    name="Class"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'class', ValorSelect(newValue))) 
                       dispatch(getclass(newValue.value))
                    }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={transactioncode} 
                    name="Transaction Code"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'transactioncode', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={accounttype} 
                    name="Account Type"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'accounttype', ValorSelect(newValue))) 
                    }}
                />

                
             <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={documentsing} 
                    name="Document Sing"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'documentsign', ValorSelect(newValue))) 
                    }}
                />

    
             <Autocompetesinapp 
                    options={departament} 
                    name="Departament"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'departament', ValorSelect(newValue))) 
                       dispatch(getResponsible(newValue.value))
                    }}
                />

            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={responsible} 
                    name="Responsible"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'responsible', ValorSelect(newValue))) 
                    }}
                />
               

                <FormControlLabel
                    control={ <Checkbox
                      checked={form.exlcuircedarcreek}
                      onChange={x => {setForm(_.set({ ...form }, 'exlcuircedarcreek', x.target.checked))}}
                      color="primary"
                    />}
                    label="Excluir Cedar Creek International"
                  />


                 <Typography variant='h6'>Opciones</Typography>
                  <Divider classes={{root: classes.divider}}/>

                  <Autocompetesinapp 
                    options={ordenarpor} 
                    name="Tipo de Reporte"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                    }}
                />

                  <Typography variant='h6'>Versión</Typography>
                 <Divider classes={{root: classes.divider}}/>

                    <Autocompetesinapp 
                    options={versionccl} 
                    name="Versión"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'version', ValorSelect(newValue))) 
                    }}
                />

        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default DocumentBalance;

DocumentBalance.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
