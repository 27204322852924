import _ from 'lodash';
import React, { useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarGarantias,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';

const Garantias = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
    const { hotel,touroperatorgroup,market,paymentcondition,invtype,estatusfacturas,incluirfacturas} = useSelector(({ ejecucion }) => ejecucion);
     const { form, setForm } = useForm({
        "hotel": null,
        "touroperatorgroup": null,
        "paymentcondition": null,
        "market": null,
        "invtype": null,
        "checkinfrom":null,
        "checkinto":null,
        "invnumberfrom":null,
        "invnumberto":null,
        "invdatefrom":null,
        "invdateto":null,
        "estatusfacturas":null,
        "incluirfacturas":null
     });

      useEffect(() => {
        dispatch(IniciarGarantias());
     }, [dispatch ]);


     const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}

        if(form.paymentcondition){variables += `|${form.paymentcondition}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.invtype){variables += `|${form.invtype}`
        }else{variables += "|null"}
        
        if(form.checkinfrom){variables += `|${form.checkinfrom},`
        }else{variables += "|null,"}

        if(form.checkinto){variables += `${form.checkinto}`
        }else{variables += "null"}

        if(form.invnumberfrom){variables += `|${form.invnumberfrom},`
        }else{variables += "|null,"}

        if(form.invnumberto){variables += `${form.invnumberto}`
        }else{variables += "null"}
        
        if(form.invdatefrom){variables += `|${form.invdatefrom},`
        }else{variables += "|null,"}

        if(form.invdateto){variables += `${form.invdateto}`
        }else{variables += "null"}

        if(form.estatusfacturas){variables += `|${form.estatusfacturas}`
        }else{variables += "|null"}

        if(form.incluirfacturas){variables += `|${form.incluirfacturas}`
        }else{variables += "|null"}

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

        dispatch(SolicitarEjecucion(Solicitar))
     };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  
            <Typography color='inherit'>Criterios de Búsqueda</Typography>
            <Divider classes={{root: classes.divider}}/>

                <Autocompetesinapp 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                 <Autocompetesinapp 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                    options={paymentcondition} 
                    name="Payment Condition"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={market} 
                  name="Market"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                  multiple 
                  disableCloseOnSelect 
                  options={invtype} 
                  name="Invoice Type"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'invtype', ValorSelect(newValue))) 
                  }}
                />

                <div className={classes.input} >
                        <Pickersinapp
                          label="Check In Date From"
                          value={form.checkinfrom}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinfrom', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinfrom', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Check In Date To"
                          value={form.checkinto}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'checkinto', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'checkinto', null))}}
                        />
                  </div>

                  <div className={classes.input} >
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Invoice Number From" 
                     variant="outlined"
                     value={form.invnumberfrom}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberfrom', value.target.value)) } 
                     style={{paddingRight:10}}
                     InputProps={{
                        inputComponent: NumberFormatEnteroPositivo,
                       }}
                     />

                  <TextField 
                     className={classes.textlabel}
                     size='small'
                     label="Invoice Number To" 
                     variant="outlined"
                     value={form.invnumberto}
                     onChange={(value) => setForm(_.set({ ...form }, 'invnumberto', value.target.value)) } 
                     InputProps={{
                        inputComponent: NumberFormatEnteroPositivo,
                       }}/>
                </div>

                  <div className={classes.input} >
                        <Pickersinapp
                          label="Invoice Date From"
                          value={form.invdatefrom}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invdatefrom', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invdatefrom', null))}}
                          style={{paddingRight: 10}}
                       />
                       <Pickersinapp
                          label="Invoice Date To"
                          value={form.invdateto}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invdateto', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invdateto', null))}}
                        />
                  </div>

                  <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={estatusfacturas} 
                  name="Estatus Facturas"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'estatusfacturas', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                   options={incluirfacturas} 
                  name="¿Incluir Facturas Canceladas?"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'incluirfacturas', ValorSelect(newValue))) 
                  }}
                />

        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default Garantias;

Garantias.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
