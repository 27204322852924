import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarPresupuestoCobrosBruto,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';

const PresupuestoCobrosBrutos = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,cartera,frecuency} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
        "collectionbudgedate":null,
        "agebalancedate":null,
        "hotel": null,
        "hotelgroup": null,
        "touroperator": null,
        "touroperatorgroup": null,
        "market": null,
        "paymentcondition": null,
        "cartera": null,
        "forecastcollectionsbudgetmonth": null,
        "frecuencia":'',
        "SheetConsolidado":'Si'
    });

     useEffect(() => {
        dispatch(IniciarPresupuestoCobrosBruto());
     }, [dispatch ]);



    const ejecucion = () => {
    
        let variables = "";
        
      if(form.collectionbudgedate){variables += `${form.collectionbudgedate}`
      }else{variables += "null"}

      if(form.hotel){variables += `|${form.hotel}`
      }else{variables += "|null"}

      if(form.hotelgroup){variables += `|${form.hotelgroup}`
      }else{variables += "|null"}

      if(form.touroperator){variables += `|${form.touroperator}`
      }else{variables += "|null"}

      if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
      }else{variables += "|null"}

      if(form.paymentcondition){variables += `|${form.paymentcondition}`
      }else{variables += "|null"}

      if(form.market){variables += `|${form.market}`
      }else{variables += "|null"}

      if(form.cartera){variables += `|${form.cartera}`
      }else{variables += "|null"}
     
      if(form.forecastcollectionsbudgetmonth){variables += `|${form.forecastcollectionsbudgetmonth}`
      }else{variables += "|null"}

      if(form.frecuencia){variables += `|${form.frecuencia}`
      }else{variables += "|null"}

      if(form.agebalancedate){variables += `|${form.agebalancedate}`
      }else{variables += "|null"}

      if(form.SheetConsolidado){variables += `|${form.SheetConsolidado}`
      }else{variables += "|null"}
     
        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

         dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>
            <div>
              <KeyboardDatePicker
                       openTo="year"
                        views={["year", "month"]}
                        size='small'
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="MM/YYYY"
                        label="Collection Budge Date"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                      }}
                      inputVariant="outlined"
                      className={classes.textlabel}
                      value={form.collectionbudgedate}
                      onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'collectionbudgedate', `${date.format('YYYYMM')}`)) : setForm(_.set({ ...form }, 'collectionbudgedate', null))}}
                      style={{paddingRight:10,width:350}}
                  />
            </div>
                <Pickersinapp
                          label="Age Balance Date"
                          value={form.agebalancedate}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'agebalancedate', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'agebalancedate', null))}}
                          style={{paddingRight:10,width:350}}
                 />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                  options={hotelgroup} 
                  name="Hotel Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                  }}
                />

                <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

               <Autocompetesinapp 
                  multiple 
                  disableCloseOnSelect 
                  options={touroperatorgroup} 
                  name="Tour Operator Group"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                  }}
                />

            <Autocompetesinapp 
                   multiple 
                  disableCloseOnSelect 
                  options={market} 
                  name="Market"
                  onChange={(event, newValue) => {
                    setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                  }}
                />

              <Autocompetesinapp 
                multiple 
                disableCloseOnSelect 
                    options={paymentcondition} 
                    name="Payment Condition"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'paymentcondition', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                   multiple 
                   disableCloseOnSelect 
                    options={cartera} 
                    name="Cartera"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'cartera', ValorSelect(newValue))) 
                    }}
                />

                <div>
                  <TextField 
                    className={classes.textlabel}
                     size='small'
                     label="Forecast Collections Budget Month" 
                     variant="outlined"
                     value={form.forecastcollectionsbudgetmonth}
                     onChange={(value) => setForm(_.set({ ...form }, 'forecastcollectionsbudgetmonth', value.target.value)) } 
                     style={{width:350}}
                     InputProps={{
                      inputComponent: NumberFormatEnteroPositivo,
                     }}
                     />

                </div>

                <Autocompetesinapp 
                    options={frecuency} 
                    name="Frecuency"
                    onChange={(event, newValue) => {
                      setForm(_.set({ ...form }, 'frecuencia', ValorSelect(newValue))) 
                    }}
                />

                  <Autocompetesinapp 
                        options={[{value:'Si',label:'Si'},{value:'No',label:'No'}]} 
                        name="¿Incluir SubTotales en Sheet Consolidado?"
                        onChange={(event, newValue) => {
                          setForm(_.set({ ...form }, 'SheetConsolidado', ValorSelect(newValue))) 
                        }}
                        label="¿Incluir SubTotales en Sheet Consolidado?"
                    />
           
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
            </DialogContent>  
        </Dialog>
    )
};

export default PresupuestoCobrosBrutos;

PresupuestoCobrosBrutos.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
