import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import useForm from '@jumbo/utils/useForm';
import { IniciarPresupuestoCobrosBruto,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import {NumberFormatEnteroPositivo} from './utils/NumberFormatCustom';

const PresupuestoCobrosBrutos = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,paymentcondition,cartera,frecuency} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
        "fechainicial":null,
        "fechafinal":null,
        "datefrom":null,
        "dateto":null,
      
    });

     useEffect(() => {
        dispatch(IniciarPresupuestoCobrosBruto());
     }, [dispatch ]);



    const ejecucion = () => {
    
        let variables = "";
        
   
      if(form.fechainicial){variables += `|${form.fechainicial},`
      }else{variables += "null,"}

      if(form.fechafinal){variables += `${form.fechafinal}`
      }else{variables += "null"}

      if(form.datefrom){variables += `|${form.datefrom},`
      }else{variables += "|null,"}

      if(form.dateto){variables += `${form.dateto}`
      }else{variables += "null"}

 
        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":null,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":null, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }

         dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
        <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
             <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
             <DialogContent dividers>
            <div className={classes.root}>  
         
             <Typography variant='h4'>Criterios de Búsqueda</Typography>
              <Divider classes={{root: classes.divider}}/>
            <div className='flex flex-row w-full'>
                           <Pickersinapp
                          label="Fecha Inicial"
                          value={form.fechainicial}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'fechainicial', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'fechainicial', null))}}
                          className="w-full"
                          style={{paddingRight:10,width:400}}
                 />

                    <Pickersinapp
                          label="Fecha Final"
                          value={form.fechafinal}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'fechafinal', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'fechafinal', null))}}
                          className="w-full"
                          style={{paddingRight:10,width:400}}
                 />
            </div>
           
            <div>
                  <Pickersinapp
                          label="Daily Journal Date From"
                          value={form.datefrom}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'datefrom', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'datefrom', null))}}
                          style={{paddingRight:10,width:400}}
                 />

                <Pickersinapp
                          label="Daily Journal Date to"
                          value={form.dateto}
                          onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'dateto', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'dateto', null))}}
                          style={{paddingRight:10,width:400}}
                 />
          </div>
                       
              </div>
        
              <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
            </DialogContent>  
        </Dialog>
    )
};

export default PresupuestoCobrosBrutos;

PresupuestoCobrosBrutos.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
