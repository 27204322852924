import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { Paper, Table, TableCell, TableContainer, TableRow, Button, Typography } from '@material-ui/core';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  BusquedaItemsPresupuestoCobros,
  getHotelesPresupuestoConceptoFiltrado,
  getTourOperatorPresupuesto,
  getLisItemsPresupuestoCobros,
  getItemsPresupuestoCobros,
  grabarItemsPresupuestoCobros,
  elimienatItemsPresupuestoCobros,
  iniciarItemsPresupuestoCobros,
  getCarterasPresupuesto,
  updateItemsPresupuestoCobros,
  FijarFechaPresupuestoDeduccion
} from '../../redux/actions/Mantenimiento';
import TextField from '@material-ui/core/TextField';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import Grid from '@material-ui/core/Grid';
import { makeStyles, alpha, lighten } from '@material-ui/core/styles';
import useForm from '@jumbo/utils/useForm';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import Swal from 'sweetalert2';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { SET_CLOSEPRESUPUESTOCOBRODIALOG } from '@jumbo/constants/ActionTypes';
import { NumberFormatDecimalNegativo } from '@jumbo/utils/NumberFormatCustom';
import ReactExport from 'react-export-excel';
import { fetchError } from '../../redux/actions/Common';
import axios from '@jumbo/constants/axios';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 350,
    minHeight: 350,
  },
  table: {
    minWidth: 750,
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  button: {
    textTransform: 'none',
  },
  buttonerror: {
    textTransform: 'none',
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  roottoolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.black,
    },
    background: theme.palette.primary.main,
  },
  textlabel: {
    '& .MuiFormLabel-root': {
      color: '#232322',
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
  input: {
    '& .MuiTextField-root': {
      width: '50%',
    },
  },
  buscar: {
    '& .CmtSearch-root': {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    },
  },
  CmtCard: {
    marginBottom: 10,
  },
}));

const headCells = [
  {
    id: 'hotel',
    numeric: false,
    disablePadding: false,
    label: 'Hotel',
  },
  {
    id: 'cartera',
    numeric: false,
    disablePadding: false,
    label: 'Cartera',
  },
  {
    id: 'tourOp',
    numeric: false,
    disablePadding: false,
    label: 'Tour Operator',
  },
  {
    id: 'item',
    numeric: false,
    disablePadding: false,
    label: 'Conceptos',
  },
  {
    id: 'importe',
    numeric: false,
    disablePadding: false,
    label: 'Importe',
  },
  {
    id: 'fecha',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
  },
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function PlantillasForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    listitemspresupuestocobros,
    hoteles,
    listitempresupuestos,
    touroperator,
    itempresupuestocobrobyid,
    carteras,
    presupuestoCobroDialog,
  } = useSelector(({ mantenimiento }) => mantenimiento);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const { form, setForm } = useForm(itempresupuestocobrobyid);

  useEffect(() => {
    axios.get('generica/fechainifinmesactual').then(response => {
      setForm({
        ...form,
        fechaInicial: response.data.primerDia,
        fechaFinal: response.data.ultimoDia,
      });
      dispatch(
        BusquedaItemsPresupuestoCobros('-10000', '-10000', '-10000', '-10000', '-10000', response.data.primerDia, response.data.ultimoDia),
      );
    });

   
    dispatch(getCarterasPresupuesto());
    dispatch(getHotelesPresupuestoConceptoFiltrado());
    dispatch(getLisItemsPresupuestoCobros());

   
    
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTourOperatorPresupuesto(form.tourOpGroupId));
  }, [form.tourOpGroupId]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return listitemspresupuestocobros;
      }
      return TableUtils.filterArrayByString(listitemspresupuestocobros, _searchTerm);
    }
    if (listitemspresupuestocobros) {
      setData(getFilteredArray(listitemspresupuestocobros, searchTerm));
    }
  }, [listitemspresupuestocobros, searchTerm]);

  useEffect(() => {
    setForm(itempresupuestocobrobyid);
  }, [itempresupuestocobrobyid, setForm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <PageContainer heading="Conceptos Deducciones Operativas -Presupuesto de Cobros">
      <CmtCard className={classes.CmtCard}>
        <CmtCardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {hoteles.length > 0 && (
                <Autocompetesinapp
                  options={hoteles}
                  value={hoteles.find(x => x.value === form.hotelID.toString())}
                  name="Hotel "
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setForm(_.set({ ...form }, 'hotelID', ValorSelect(newValue)));
                    } else {
                      setForm(_.set({ ...form }, 'hotelID', '-10000'));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {carteras.length > 0 && (
                <Autocompetesinapp
                  options={carteras}
                  value={carteras.find(x => x.value === form.tourOpGroupId.toString())}
                  name="Cartera"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setForm(_.set({ ...form }, 'tourOpGroupId', ValorSelect(newValue)));
                    } else {
                      setForm(_.set({ ...form }, 'tourOpGroupId', '-10000'));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {touroperator.length > 0 && (
                <Autocompetesinapp
                  options={touroperator}
                  value={touroperator.find(x => x.value === form.tourOpID.toString())}
                  name="Tour Operator"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setForm(_.set({ ...form }, 'tourOpID', ValorSelect(newValue)));
                    } else {
                      setForm(_.set({ ...form }, 'tourOpID', '-10000'));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {listitempresupuestos.length > 0 && (
                <Autocompetesinapp
                  options={listitempresupuestos}
                  value={listitempresupuestos.find(x => x.value === form.presupuestoItemID.toString())}
                  name="Conceptos"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setForm(_.set({ ...form }, 'presupuestoItemID', ValorSelect(newValue)));
                    } else {
                      setForm(_.set({ ...form }, 'presupuestoItemID', '-10000'));
                    }
                  }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                className={classes.textlabel}
                size="small"
                label="Importe"
                variant="outlined"
                value={form.importe}
                onChange={value => {
                  setForm(_.set({ ...form }, 'importe', value.target.value));
                }}
                InputProps={{
                  inputComponent: NumberFormatDecimalNegativo,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Pickersinapp
                fullWidth
                label="Fecha"
                value={form.fechaDummy}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'fechaDummy', `${date.format('YYYYMMDD')}`))
                    : setForm(_.set({ ...form }, 'fechaDummy', 'na'));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className={classes.textlabel}
                size="small"
                label="Comentario"
                variant="outlined"
                value={form.comentario}
                onChange={value => setForm(_.set({ ...form }, 'comentario', value.target.value))}
              />
            </Grid>
            <Grid container justifyContent="center" spacing={2} style={{ marginTop: 10 }}>
              <Button
                onClick={() => dispatch(iniciarItemsPresupuestoCobros())}
                style={{ margin: 5 }}
                size="small"
                className={classes.button}
                variant="contained">
                Limpiar
              </Button>

              <Button
                onClick={() =>
                  dispatch(
                    BusquedaItemsPresupuestoCobros(
                      form.presupuestoPartidaID,
                      form.presupuestoItemID,
                      form.hotelID,
                      form.tourOpID,
                      form.tourOpGroupId,
                      '-10000',
                      '-10000',
                    ),
                  )
                }
                style={{ margin: 5 }}
                size="small"
                className={classes.button}
                variant="contained">
                Buscar
              </Button>

              <Button
                style={{ margin: 5 }}
                size="small"
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => {
                  form.usuarioID = usuarioID;

                  if (form.hotelID === '-10000') {
                    dispatch(fetchError('Hotel Requerido'));
                  } else if (form.tourOpGroupId === '-10000') {
                    dispatch(fetchError('Cartera Requerido'));
                  } else if (form.presupuestoItemID === '-10000') {
                    dispatch(fetchError('Concepto Requerido'));
                  } else if (!form.importe) {
                    dispatch(fetchError('Importe Requerido'));
                  } else if (
                    form.presupuestoItemID !== '-10000' &&
                    form.hotelID !== '-10000' &&
                    form.importe &&
                    form.tourOpGroupId !== '-10000'
                  ) {
                    dispatch(grabarItemsPresupuestoCobros(form));
                  }
                }}>
                Grabar
              </Button>

              {form.presupuestoPartidaID && (
                <Button
                  style={{ margin: 5 }}
                  size="small"
                  className={classes.buttonerror}
                  variant="contained"
                  onClick={() => {
                    Swal.fire({
                      title: '¿Estás seguro(a) de Eliminar?',
                      text: '¡No podrás recuperar este cambio!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: 'Si, Eliminar',
                      cancelButtonText: 'No, Mantener',
                    }).then(result => {
                      if (result.value) {
                        dispatch(elimienatItemsPresupuestoCobros(form));
                        dispatch(iniciarItemsPresupuestoCobros());
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                      }
                    });
                  }}>
                  Eliminar
                </Button>
              )}
            </Grid>
          </Grid>
        </CmtCardContent>
      </CmtCard>

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.roottoolbar}>
            <div className={classes.headerTitle}>
              <Typography
                className={classes.titlecondicion}
                variant="h4"
                id="tableTitle"
                component="div"
                style={{ marginTop: 4 }}>
                Listado de Conceptos Presupestos Neto
              </Typography>

              <React.Fragment>
                <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon />
              </React.Fragment>

              <React.Fragment>
                <ExcelFile
                  element={
                    <Button variant="contained" color="secondary">
                      Export Excel
                    </Button>
                  }>
                  <ExcelSheet data={listitemspresupuestocobros} name="Presupuesto Cobro">
                    <ExcelColumn label="Hotel" value="hotel" />
                    <ExcelColumn label="Cartera" value="cartera" />
                    <ExcelColumn label="Tour Operator" value="tourOp" />
                    <ExcelColumn label="Conceptos" value="item" />
                    <ExcelColumn label="Importe" value="importe" />
                    <ExcelColumn label="Fecha" value="fecha" />
                  </ExcelSheet>
                </ExcelFile>
              </React.Fragment>
            </div>

            <div className={classes.headerTitle}>
              <Pickersinapp
                fullWidth
                label="Fecha Desde"
                value={form.fechaInicial}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'fechaInicial', `${date.format('YYYYMMDD')}`))
                    : setForm(_.set({ ...form }, 'fechaInicial', 'na'));
                }}
                style={{ marginRight: 5 }}
              />
              <Pickersinapp
                fullWidth
                label="Fecha Hasta"
                value={form.fechaFinal}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'fechaFinal', `${date.format('YYYYMMDD')}`))
                    : setForm(_.set({ ...form }, 'fechaFinal', 'na'));
                }}
              />

              <Button
                onClick={() =>
                  dispatch(
                    BusquedaItemsPresupuestoCobros(
                      form.presupuestoPartidaID,
                      form.presupuestoItemID,
                      form.hotelID,
                      form.tourOpID,
                      form.tourOpGroupId,
                      form.fechaInicial,
                      form.fechaFinal,
                    ),
                  )
                }
                style={{ margin: 5 }}
                size="small"
                className={classes.button}
                variant="contained">
                Buscar
              </Button>
              
              <Button
                onClick={() => {
                  Swal.fire({
                    title: '¿Estás seguro(a) de Fijar Fecha?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'Si, Fijar',
                    cancelButtonText: 'No, Mantener',
                  }).then(result => {
                    if (result.value) {
                      if(form.fechaInicial && form.fechaFinal){
                       dispatch(FijarFechaPresupuestoDeduccion({fechaInicial:form.fechaInicial,fechaFinal:form.fechaFinal}));
                       dispatch(
                        BusquedaItemsPresupuestoCobros(
                          form.presupuestoPartidaID,
                          form.presupuestoItemID,
                          form.hotelID,
                          form.tourOpID,
                          form.tourOpGroupId,
                          form.fechaInicial,
                          form.fechaFinal,
                        ))
                     }
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                      Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                    }
                  });
                }}
                style={{ margin: 5 }}
                size="small"
                className={classes.button}
                variant="contained"
                color="secondary"
                >
                Fijar
              </Button>
            </div>
          </Toolbar>

          <TableContainer className={classes.container}>
            <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
              <TableHeadFormulario classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

              <TableBody>
                {!!data.length ? (
                  stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow hover tabIndex={-1} key={row.formAppID}>
                        <TableCell>{row.hotel}</TableCell>
                        <TableCell>{row.cartera}</TableCell>
                        <TableCell>{row.tourOp}</TableCell>
                        <TableCell>{row.item}</TableCell>
                        <TableCell>{new Intl.NumberFormat().format(row.importe)}</TableCell>
                        <TableCell>{row.fecha}</TableCell>
                        <TableCell align="right">
                          <Button
                            size="small"
                            className={classes.button}
                            variant="contained"
                            color="secondary"
                            onClick={() => dispatch(getItemsPresupuestoCobros(row.presupuestoPartidaID))}>
                            Editar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow style={{ height: 53 * 6 }}>
                    <TableCell colSpan={7} rowSpan={10}>
                      <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
          />
        </Paper>
      </div>
   
      <FormDialog fechaInicial={form.fechaInicial} fechaFinal={form.fechaFinal} />
    </PageContainer>
  );
}

const defaultFormState = {
  presupuestoPartidaID: null,
  presupuestoItemID: '-10000',
  hotelID: '-10000',
  tourOpID: '-10000',
  importe: '',
  fechaDummy: new Date(),
  comentario: '',
  usuarioID: null,
};

function FormDialog(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formDialog = useSelector(({ mantenimiento }) => mantenimiento.presupuestoCobroDialog);
  const { form, setForm } = useForm(defaultFormState);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);

  
  const initDialog = useCallback(() => {
    if (formDialog.data) {
      formDialog.data.fechaInicial = props.fechaInicial
      formDialog.data.fechaFinal = props.fechaFinal
      setForm({ ...formDialog.data });
    }
  }, [formDialog.data, setForm]);

  useEffect(() => {
    if (formDialog.props.open) {
      initDialog();
    }
  }, [formDialog.props.open, initDialog]);

  function closeComposeDialog() {
    dispatch({ type: SET_CLOSEPRESUPUESTOCOBRODIALOG, payload: false });
  }

  return (
    <>
      <Dialog open={formDialog.props.open} className={classes.dialogRoot} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitleRoot}> Editar Presupestos </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <TextField
              className={classes.textlabel}
              fullWidth
              label="Importe"
              variant="outlined"
              value={form.importe}
              onChange={value => setForm(_.set({ ...form }, 'importe', value.target.value))}
              size="small"
              style={{ marginBottom: 10 }}
              InputProps={{
                inputComponent: NumberFormatDecimalNegativo,
              }}
            />

            <Pickersinapp
              style={{ marginBottom: 10 }}
              fullWidth
              label="Fecha"
              value={form.fechaDummy}
              onChange={date => {
                date !== null
                  ? setForm(_.set({ ...form }, 'fechaDummy', `${date.format('YYYYMMDD')}`))
                  : setForm(_.set({ ...form }, 'fechaDummy', 'na'));
              }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label="Comentario"
              variant="outlined"
              value={form.comentario}
              onChange={value => setForm(_.set({ ...form }, 'comentario', value.target.value))}
              size="small"
              style={{ marginBottom: 10 }}
              multiline
              rows={3}
            />
          </div>

          <Box display="flex" justifyContent="flex-end" my={4}>
            <Button variant="contained" onClick={() => closeComposeDialog()}>
              Cancelar
            </Button>
            {form.presupuestoItemID !== null && (
              <Box ml={2}>
                <Button
                  variant="contained"
                  className={classes.buttonerror}
                  onClick={() => {
                    form.usuarioID = usuarioID;
                    Swal.fire({
                      title: '¿Estás seguro(a) de Eliminar?',
                      text: '¡No podrás recuperar este cambio!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: 'Si, Eliminar',
                      cancelButtonText: 'No, Mantener',
                    }).then(result => {
                      if (result.value) {
                        dispatch(elimienatItemsPresupuestoCobros(form));
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                      }
                    });
                  }}>
                  Eliminar
                </Button>
              </Box>
            )}
            <Box ml={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  form.usuarioID = usuarioID;
                  if (
                    form.presupuestoItemID !== '-10000' &&
                    form.hotelID !== '-10000' &&
                    form.tourOpID !== '-10000' &&
                    form.importe &&
                    form.fechaDummy
                  ) {
                    dispatch(updateItemsPresupuestoCobros(form));
                  }
                }}>
                Guardar
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
