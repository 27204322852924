//import _ from 'lodash';
import React  from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
//import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
//import useForm from '@jumbo/utils/useForm';
//import { IniciarCollectionsbyHotelbyMonth,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
// import {ValorSelect} from './utils/Helper';
// import Autocompetesinapp from './utils/Autocompetesinapp';
// import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';


const CollectionsbyHotelbyMonth = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
  //  const dispatch = useDispatch();
  //   const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  //   const { hotel, hotelgroup } = useSelector(({ ejecucion }) => ejecucion);
    
  // const { form, setForm } = useForm({
  //     "hotel": null,
  //     "hotelgroup": null,
  //     "datedesde":null,
  //     "datehasta": null,
  //   });

    //  useEffect(() => {
    //    dispatch(IniciarCollectionsbyHotelbyMonth());
    //  }, [dispatch ]);


    //  const ejecucion = () => {
    //     let variables = "";
        
    //      if(form.hotel){variables += `${form.hotel}`
    //      }else{variables += "null"}

    //      if(form.hotelgroup){variables += `|${form.hotelgroup}`
    //      }else{variables += "|null"}
              
    //     if(form.datedesde){variables += `|${form.datedesde},`
    //     }else{variables += "|null,"}

    //     if(form.datehasta){variables += `${form.datehasta}`
    //     }else{variables += "null"}
      
    //     let Solicitar = {
    //       "FormAppID":formApp.formAppID,
    //       "UsuarioID":usuarioID,
    //       "OrdenarPorID":null,
    //       "Computador":"192.163", 
    //       "UserNameComputador":"192.163", 
    //       "ValorVersion":null, 
    //       "CriteriosEjecucion":variables, 
    //       "CriteriosEjecucionExclusion":"" 
    //     }

    //     dispatch(SolicitarEjecucion(Solicitar))
        
    //    };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  
            <Typography variant='h4'>Criterios de Búsqueda</Typography>
            <Divider classes={{root: classes.divider}}/>


               

        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={onCloseDialog}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default CollectionsbyHotelbyMonth;

CollectionsbyHotelbyMonth.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
