import React, { useEffect, useState, useCallback } from 'react';
import { Paper, Table, TableCell, TableContainer, TableRow, Button, Typography, TextField } from '@material-ui/core';
import { makeStyles, alpha, lighten } from '@material-ui/core/styles';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import TableBody from '@material-ui/core/TableBody';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import Toolbar from '@material-ui/core/Toolbar';
import CmtSearch from '@coremat/CmtSearch';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableUtils from '@jumbo/utils/TableUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { SET_OPENPERIODOSSEMANALDIALOG, SET_CLOSEPERIODOSSEMANALDIALOG } from '@jumbo/constants/ActionTypes';
import {
  getPeriodosSemanal,
  getbyidPeriodoSemanal,
  addPeriodoSemanal,
  updPeriodoSemanal,
  EliminarPeriodoSemanal,
  GetCamposFormulario,
  Getrowsheader,
} from '../../redux/actions/Mantenimiento';
import GridContainer from '@jumbo/components/GridContainer';
import { Grid } from '@material-ui/core';
import Pickersinapp from 'routes/Ejecucion/utils/Pickersinapp';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Swal from 'sweetalert2';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { ValorSelect } from 'routes/Ejecucion/utils/Helper';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';
import _ from 'lodash';
import useForm from '@jumbo/utils/useForm';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useStyles2 from '@jumbo/components/ListadoEjecucion/EjecucionStyle';
import { NumberFormatDecimalPositivo, NumberFormatEnteroPositivo } from '../../@jumbo/utils/NumberFormatCustom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 550,
    minHeight: 550,
  },
  table: {
    minWidth: 200,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  button: {
    textTransform: 'none',
    color: theme.palette.common.black,
  },
  buttonerror: {
    textTransform: 'none',
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  roottoolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.black,
    },
    background: theme.palette.primary.main,
  },
  textlabel: {
    '& .MuiFormLabel-root': {
      color: '#232322',
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
  input: {
    '& .MuiTextField-root': {
      width: '50%',
    },
  },
  buscar: {
    '& .CmtSearch-root': {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    },
  },
  CmtCard: {
    marginBottom: 10,
  },
}));

function TableHeadSemanal({ classes, order, orderBy, onRequestSort }) {
  const { CamposHeader } = useSelector(({ mantenimiento }) => mantenimiento);
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {CamposHeader.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const defaultFormState = {
  presupuestoPeriodoSemanalID: null,
  fechaInicial: null,
  fechaFinal: null,
  semanaNumero: null,
  regular: null,
  pgs: null,
  otras: null,
  usuarioID: null,
};

export default function InformesForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { form, setForm } = useForm({ FechaInicial: null, FechaFinal: null });
  const formDialog = useSelector(({ mantenimiento }) => mantenimiento.dialogPeriodosSemanal);

  useEffect(() => {
    dispatch(GetCamposFormulario('PRESUPUESTO_SEMANAL'));
    dispatch(Getrowsheader('PRESUPUESTO_SEMANAL'));
  }, []);

  useEffect(() => {
    dispatch(getPeriodosSemanal(form));
  }, []);

  return (
    <PageContainer heading="Periodos Ejecución Presupuesto Semanal">
      <CmtCard className={classes.CmtCard}>
        <CmtCardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={5}>
              <Pickersinapp
                fullWidth
                label="Fecha Desde *"
                value={form.FechaInicial}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'FechaInicial', `${date.format('YYYYMMDD')}`))
                    : setForm(_.set({ ...form }, 'FechaInicial', 'na'));
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <Pickersinapp
                fullWidth
                label="Fecha Hasta *"
                value={form.FechaFinal}
                onChange={date => {
                  date !== null
                    ? setForm(_.set({ ...form }, 'FechaFinal', `${date.format('YYYYMMDD')}`))
                    : setForm(_.set({ ...form }, 'FechaFinal', 'na'));
                }}
              />
            </Grid>
            <Grid item xs={2} className="flex justify-end">
              <Button
                variant="contained"
                color="secondary"
                className="mr-4"
                onClick={() => dispatch(getPeriodosSemanal(form))}>
                Buscar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => dispatch({ type: SET_OPENPERIODOSSEMANALDIALOG, payload: defaultFormState })}>
                Agregar
              </Button>
            </Grid>
          </Grid>
        </CmtCardContent>
      </CmtCard>
      <Lista />
      <FormDialog />
    </PageContainer>
  );
}

function Lista() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { PeriodosSemanal, CamposHeader } = useSelector(({ mantenimiento }) => mantenimiento);
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const page = 0;
  const rowsPerPage = 300;

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return PeriodosSemanal;
      }
      return TableUtils.filterArrayByString(PeriodosSemanal, _searchTerm);
    }
    if (PeriodosSemanal) {
      setData(getFilteredArray(PeriodosSemanal, searchTerm));
    }
  }, [PeriodosSemanal, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
            <TableHeadSemanal classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {!!data.length ? (
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow hover tabIndex={-1} key={row.countryHoliDayID}>
                      {CamposHeader.map(headCell => (
                        <TableCell>{row[headCell.id]}</TableCell>
                      ))}

                      {/* <TableCell>{row.fechaInicial}</TableCell>
                      <TableCell>{row.fechaFinal}</TableCell>
                      <TableCell>{row.semanaNumero}</TableCell>
                      <TableCell>{row.regular}</TableCell>
                      <TableCell>{row.pgs}</TableCell>
                      <TableCell>{row.otras}</TableCell> */}
                      <TableCell align="right">
                        <IconButton size="medium" color="secondary">
                          <EditIcon
                            onClick={() => {
                              dispatch(getbyidPeriodoSemanal(row.presupuestoPeriodoSemanalID));
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

function FormDialog(props) {
  const dispatch = useDispatch();
  const classes2 = useStyles2();
  const classes = useStyles();
  const formDialog = useSelector(({ mantenimiento }) => mantenimiento.dialogPeriodosSemanal);
  const CamposFormulario = useSelector(({ mantenimiento }) => mantenimiento.CamposFormularios);
  const { form, setForm } = useForm(defaultFormState);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);

  const initDialog = useCallback(() => {
    if (formDialog.data) {
      setForm({ ...formDialog.data });
    }
  }, [formDialog.data, setForm]);

  useEffect(() => {
    if (formDialog.props.open) {
      initDialog();
    }
  }, [formDialog.props.open, initDialog]);

  function closeComposeDialog() {
    dispatch({ type: SET_CLOSEPERIODOSSEMANALDIALOG, payload: false });
  }

  const isFormValid = () => {
    return form.fechaInicial && form.fechaFinal && form.semanaNumero && form.regular && form.pgs && form.otras;
  };

  function getTextLabel(value) {
    let textoLabel = '';
    if (CamposFormulario.filter(x => x.fieldName === value).length > 0) {
      textoLabel = CamposFormulario.filter(x => x.fieldName === value)[0].textoLabel;
    }
    return textoLabel;
  }

  return (
    <>
      <Dialog open={formDialog.props.open} className={classes.dialogRoot} fullWidth maxWidth="sm">
        <DialogTitle className={classes.dialogTitleRoot}> Periodos Ejecución Presupuesto Semanal</DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Pickersinapp
              fullWidth
              label={getTextLabel('FECHA_INICIAL') !== '' ? getTextLabel('FECHA_INICIAL') : 'Periodo'}
              value={form.fechaInicial}
              onChange={date => {
                date !== null
                  ? setForm(_.set({ ...form }, 'fechaInicial', `${date.format('YYYYMMDD')}`))
                  : setForm(_.set({ ...form }, 'fechaInicial', 'na'));
              }}
              style={{ marginBottom: 10 }}
            />

            <Pickersinapp
              fullWidth
              label={getTextLabel('FECHA_FINAL') !== '' ? getTextLabel('FECHA_FINAL') : 'Periodo'}
              value={form.fechaFinal}
              onChange={date => {
                date !== null
                  ? setForm(_.set({ ...form }, 'fechaFinal', `${date.format('YYYYMMDD')}`))
                  : setForm(_.set({ ...form }, 'fechaFinal', 'na'));
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('semanaNumero') !== '' ? getTextLabel('semanaNumero') : 'Semana Número'}
              variant="outlined"
              value={form.semanaNumero}
              onChange={value => setForm(_.set({ ...form }, 'semanaNumero', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatEnteroPositivo,
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('REGULAR') !== '' ? getTextLabel('REGULAR') : 'Regular'}
              variant="outlined"
              value={form.regular}
              onChange={value => setForm(_.set({ ...form }, 'regular', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('PGS') !== '' ? getTextLabel('PGS') : 'PGS'}
              variant="outlined"
              value={form.pgs}
              onChange={value => setForm(_.set({ ...form }, 'pgs', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />

            <TextField
              className={classes.textlabel}
              fullWidth
              label={getTextLabel('OTRAS') !== '' ? getTextLabel('OTRAS') : 'OTRAS'}
              variant="outlined"
              value={form.otras}
              onChange={value => setForm(_.set({ ...form }, 'otras', value.target.value))}
              InputProps={{
                inputComponent: NumberFormatDecimalPositivo,
              }}
              style={{ marginBottom: 10 }}
            />
          </div>

          <Box display="flex" justifyContent="flex-end" my={4}>
            <Button variant="contained" onClick={() => closeComposeDialog()}>
              Cancelar
            </Button>
            {form.presupuestoPeriodoSemanalID !== null && (
              <Box ml={2}>
                <Button
                  variant="contained"
                  className={classes.buttonerror}
                  onClick={() => {
                    form.usuarioID = usuarioID;
                    Swal.fire({
                      title: '¿Estás seguro(a) de Eliminar?',
                      text: '¡No podrás recuperar este cambio!',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: 'Si, Eliminar',
                      cancelButtonText: 'No, Mantener',
                    }).then(result => {
                      if (result.value) {
                        let borrar = {
                          usuarioID: usuarioID,
                          presupuestoPeriodoSemanalID: form.presupuestoPeriodoSemanalID,
                          fechaInicial: form.fechaInicial,
                          fechaFinal: form.fechaFinal,
                        };
                        dispatch(EliminarPeriodoSemanal(borrar));
                      } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                      }
                    });
                  }}>
                  Eliminar
                </Button>
              </Box>
            )}
            <Box ml={2}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!isFormValid()}
                onClick={() => {
                  form.usuarioID = usuarioID;

                  if (form.presupuestoPeriodoSemanalID === null) {
                    dispatch(addPeriodoSemanal(form));
                  } else {
                    dispatch(updPeriodoSemanal(form));
                  }
                }}>
                Guardar
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
