import _ from 'lodash';
import React, { useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useForm from '@jumbo/utils/useForm';
import { IniciarReconciliacion,SolicitarEjecucion} from '../../redux/actions/Ejecucion';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import {ValorSelect} from './utils/Helper';
import Autocompetesinapp from './utils/Autocompetesinapp';
import Pickersinapp from './utils/Pickersinapp';
import useStyles from '@jumbo/components/ListadoEjecucion/EjecucionStyle';

const ReconciliationSabana = ({ open, onCloseDialog,formApp }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { usuarioID } = useSelector(({ auth }) => auth.authUser);
     const { hotel,hotelgroup,touroperator,touroperatorgroup,market,tiporeporte} = useSelector(({ ejecucion }) => ejecucion);
    const { form, setForm } = useForm({
      "hotel": null,
      "hotelgroup": null,
      "touroperator": null,
      "touroperatorgroup": null,
      "market": null,
      "invdate": null,
      "ordenarpor":null
    });

    useEffect(() => {
      dispatch(IniciarReconciliacion(formApp.formAppID));
    }, [dispatch,formApp.formAppID]);



    const ejecucion = () => {
        let variables = "";
        
        if(form.hotel){variables += `${form.hotel}`
        }else{variables += "null"}

        if(form.hotelgroup){variables += `|${form.hotelgroup}`
        }else{variables += "|null"}

        if(form.touroperator){variables += `|${form.touroperator}`
        }else{variables += "|null"}

        if(form.invdate){variables += `|${form.invdate}`
        }else{variables += "|null"}

        if(form.market){variables += `|${form.market}`
        }else{variables += "|null"}

        if(form.touroperatorgroup){variables += `|${form.touroperatorgroup}`
        }else{variables += "|null"}
       

        let Solicitar = {
          "FormAppID":formApp.formAppID,
          "UsuarioID":usuarioID,
          "OrdenarPorID":form.ordenarpor,
          "Computador":"192.163", 
          "UserNameComputador":"192.163", 
          "ValorVersion":form.version, 
          "CriteriosEjecucion":variables, 
          "CriteriosEjecucionExclusion":"" 
        }
        
        dispatch(SolicitarEjecucion(Solicitar))
      };

    return (
    <Dialog open={open}  className={classes.dialogRoot} fullWidth maxWidth="md" >
        <DialogTitle className={classes.dialogTitleRoot}>{formApp.titulo} </DialogTitle>
        <DialogContent dividers>
        <div className={classes.root}>  

            <Typography variant='h4'>Criterios de Búsqueda</Typography>
            <Divider classes={{root: classes.divider}}/>

            <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={hotel} 
                    name="Hotel"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotel', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={hotelgroup} 
                    name="Hotel Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'hotelgroup', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={touroperator} 
                    name="Tour Operator"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperator', ValorSelect(newValue))) 
                    }}
                />

                <Autocompetesinapp 
                    options={touroperatorgroup} 
                    name="Tour Operator Group"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'touroperatorgroup', ValorSelect(newValue))) 
                    }}
                />

              <Autocompetesinapp 
                    multiple 
                    disableCloseOnSelect 
                    options={market} 
                    name="Market"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'market', ValorSelect(newValue))) 
                    }}
                />

                <Pickersinapp
                  label="Invoice Date "
                  value={form.invdate}
                  onChange={(date) =>{date !== null ? setForm(_.set({ ...form }, 'invdate', `${date.format('YYYYMMDD')}`)) : setForm(_.set({ ...form }, 'invdate', null))}}
                  style={{paddingRight: 10}}
                />
                <Typography variant='h6'>Tipo de Reporte</Typography>
                 <Divider classes={{root: classes.divider}}/>

           
                  <Autocompetesinapp 
                    options={tiporeporte} 
                    name="Tipo de Reporte"
                    onChange={(event, newValue) => {
                       setForm(_.set({ ...form }, 'ordenarpor', ValorSelect(newValue))) 
                    }}
                />

        </div>
        <Box display="flex" justifyContent="flex-end" my={4}>
                <Button className={classes.button} variant="contained" onClick={onCloseDialog}>Cancelar</Button>
                   <Box ml={2}>
                    <Button className={classes.button}  variant="contained" color="secondary" onClick={() => ejecucion()}>
                       Ejecutar
                    </Button>
                </Box>
             </Box>
        </DialogContent>  
    </Dialog>
    )
};

export default ReconciliationSabana;

ReconciliationSabana.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
