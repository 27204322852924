import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableContainer, TableRow, Typography, Button } from '@material-ui/core';
import { makeStyles, alpha, lighten } from '@material-ui/core/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Toolbar from '@material-ui/core/Toolbar';
import CmtCard from '@coremat/CmtCard';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Autocompetesinapp from 'routes/Ejecucion/utils/Autocompetesinapp';
import { getComparator, stableSort } from '@jumbo/utils/tableHelper';
import { useDispatch, useSelector } from 'react-redux';
import TableUtils from '@jumbo/utils/TableUtils';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import NoRecordFound from '@jumbo/components/ListadoEjecucion/NoRecordFound';
import Swal from 'sweetalert2';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { SET_PORTAFOLIOSELECT } from '@jumbo/constants/ActionTypes';
import {
  getPortafoliosinicio,
  getCarterasPresupuesto,
  addCarteraPortafolioDetalle,
  getCarterasportafolioDetalle,
  EliminarcarteraPortafoliodetalle,
} from '../../redux/actions/Mantenimiento';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const headCells = [
  {
    id: 'carteras',
    numeric: false,
    disablePadding: false,
    label: 'Carteras',
  },
];

function TableHeadFormulario({ classes, order, orderBy, onRequestSort }) {
  const onSortOrderChange = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onSortOrderChange(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">Acción</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: 350,
    minHeight: 350,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
  button: {
    textTransform: 'none',
  },
  buttonerror: {
    textTransform: 'none',
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  roottoolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary.light, 0.15),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  chipsRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  dialogRoot: {
    position: 'relative',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.black,
    },
    background: theme.palette.primary.main,
  },
  textlabel: {
    '& .MuiFormLabel-root': {
      color: '#232322',
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
  input: {
    '& .MuiTextField-root': {
      width: '50%',
    },
  },
  buscar: {
    '& .CmtSearch-root': {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2),
    },
  },
  CmtCard: {
    marginBottom: 10,
  },
  CmtCardSidebar: {
    marginRight: 10,
  },
  listItem: {
    color: 'black!important',
    fontWeight: '500',
    textDecoration: 'none!important',
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingLeft: 24,
    paddingRight: 12,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: `${'white'}!important`,
      pointerEvents: 'none',
      '& .list-item-icon': {
        color: `${'white'}!important`,
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
  },
}));

export default function AgrupacionCartera() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { Portafolios, PortafoliosSelect, carteras, portafolioDetalle } = useSelector(({ mantenimiento }) => mantenimiento);
  const { usuarioID } = useSelector(({ auth }) => auth.authUser);
  const [TourOperator, setTourOperator] = useState('-10000');
  const [data, setData] = useState([]);
  const [orderBy, setOrderBy] = React.useState('descripcion');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    dispatch(getPortafoliosinicio());
    dispatch(getCarterasPresupuesto());
  }, [dispatch]);

  useEffect(() => {
    if (PortafoliosSelect.carteraGrupoPresupuestoID) {
      dispatch(getCarterasportafolioDetalle(PortafoliosSelect.carteraGrupoPresupuestoID));
    }
  }, [PortafoliosSelect]);

  useEffect(() => {
    function getFilteredArray(entities, _searchTerm) {
      if (_searchTerm.length === 0) {
        return portafolioDetalle;
      }
      return TableUtils.filterArrayByString(portafolioDetalle, _searchTerm);
    }
    if (portafolioDetalle) {
      setData(getFilteredArray(portafolioDetalle, searchTerm));
    }
  }, [portafolioDetalle, searchTerm]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <PageContainer heading="Portafolios y Carteras">
      <Grid container>
        <Grid item xs={3}>
          <CmtCard className={classes.CmtCardSidebar}>
            <Toolbar className={classes.roottoolbar}>
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                Portafolios
              </Typography>
            </Toolbar>

            <List>
              {Portafolios.length > 0 &&
                Portafolios.map(grupo => (
                  <ListItem
                    button
                    selected={PortafoliosSelect.carteraGrupoPresupuestoID === grupo.carteraGrupoPresupuestoID}
                    onClick={() => {
                      dispatch({ type: SET_PORTAFOLIOSELECT, payload: grupo });
                    }}
                    key={grupo.carteraGrupoPresupuestoID}
                    activeClassName="active"
                    className={classes.listItem}>
                    <ListItemText primary={grupo.descripcion} disableTypography />
                  </ListItem>
                ))}
            </List>
          </CmtCard>
        </Grid>

        <Grid item xs={9}>
          <CmtCard>
            <Toolbar className={classes.roottoolbar}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ marginTop: 15 }}>
                      <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                        {PortafoliosSelect.descripcion}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ flexDirection: 'row', display: 'flex', marginTop: 5 }}>
                    {carteras.length > 0 && (
                      <Autocompetesinapp
                        options={carteras}
                        name="Carteras"
                        value={carteras.find(x => x.value === TourOperator.toString())}
                        onChange={(event, newValue) => {
                          if(newValue){
                          setTourOperator(newValue?.value);
                          }
                        }}
                        fullWidth
                      />
                    )}

                    <Button
                      onClick={() => {
                        let Detalle = {
                          carteraGrupoPresupuestoID: PortafoliosSelect.carteraGrupoPresupuestoID,
                          tourOpGroupID: TourOperator.toString(),
                          usuarioID: usuarioID,
                        };
                        dispatch(addCarteraPortafolioDetalle(Detalle));
                      }}
                      style={{ marginRight: 5, marginLeft: 6 }}
                      size="small"
                      //className={classes.button}
                      variant="contained"
                      color="secondary">
                      Agregar
                    </Button>

                    {/* <IconButton size="medium" color="secondary">
                      <AddBoxIcon
                        onClick={() => {
                          let Detalle = {
                            carteraGrupoPresupuestoID: PortafoliosSelect.carteraGrupoPresupuestoID,
                            tourOpGroupID: TourOperator.toString(),
                            usuarioID: usuarioID,
                          };
                          dispatch(addCarteraPortafolioDetalle(Detalle));
                        }}
                      />
                    </IconButton> */}
                  </div>
                </Grid>
              </Grid>
            </Toolbar>

            <TableContainer className={classes.container}>
              <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="sticky enhanced table">
                <TableHeadFormulario classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

                <TableBody>
                  {!!data.length ? (
                    stableSort(data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => (
                        <TableRow hovers tabIndex={-1} key={row.formAppID}>
                          <TableCell>{row.cartera}</TableCell>
                          <TableCell align="right">
                            <IconButton size="medium" color="secondary">
                              <DeleteForeverIcon
                                onClick={() => {
                                  Swal.fire({
                                    title: '¿Estás seguro(a) de Eliminar?',
                                    text: '¡No podrás recuperar este cambio!',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: 'red',
                                    confirmButtonText: 'Si, Eliminar',
                                    cancelButtonText: 'No, Mantener',
                                  }).then(result => {
                                    if (result.value) {
                                      let value = {
                                        carteraGrupoPresupuestoID: PortafoliosSelect.carteraGrupoPresupuestoID,
                                        carteraGrupoPresupuestoDetalleID: row.carteraGrupoPresupuestoDetalleID,
                                      };
                                      dispatch(EliminarcarteraPortafoliodetalle(value));
                                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                                      Swal.fire('Cancelado', 'No se realizó este cambio :)', 'error');
                                    }
                                  });
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow style={{ height: 53 * 6 }}>
                      <TableCell colSpan={7} rowSpan={10}>
                        <NoRecordFound>No se han encontrado registros.</NoRecordFound>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Filas por página"
            />
          </CmtCard>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
